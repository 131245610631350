import { ref, isRef, onUnmounted, watch, computed } from 'vue'
import { convert, on } from '@/composables/useServerEvents'
import { ServerEvent } from '@/enums'

/**
 * Define cache service for specific api
 *
 * @param {string} name The name of service (To implement get by id lock)
 * @param {Function} apiGetByKey The function api get by identifier.
 * @param {Function} itemToKey Something like `serializer` to transform object to key, which will be
 *   used to save item into cache. **IMPORTANT!** Name of arguments of this function should be the
 *   same as names of variable from backend in related object for correct SSE working.
 * @param {string} eventNameUpdated The event name updated.
 * @param {string} eventNameDeleted The event name deleted.
 * @param {number} [ttl=3000] The time to live after there are no usages for cache item. Default is
 *   `3000`. Default is `3000`
 */
export const defineService = (
  name,
  apiGetByKey,
  itemToKey = ({ id }) => id,
  eventNameUpdated = '',
  eventNameDeleted = '',
  ttl = 3000
) => {
  const cacheMap = new Map()
  const timeoutsRemoveMap = new Map()

  const _setCacheItem = (key, item) => cacheMap.set(key, { usages: 0, item: ref(item) })

  /**
   * Increases usages counter for mapped cache item.
   *
   * @param {string} key The identifier of cached item.
   */
  const useCacheItem = key => {
    clearTimeout(timeoutsRemoveMap.get(key))
    const cacheItem = cacheMap.get(key)
    if (!cacheItem) return
    cacheItem.usages += 1
  }

  /**
   * Decrease usages counter for mapped cache item. When usages counter is 0, remove timeout starts
   * after TTL.
   *
   * @param {string} key The identifier of cached item.
   */
  const unuseCacheItem = key => {
    const cacheItem = cacheMap.get(key)
    if (!cacheItem || cacheItem.usages <= 0) return
    cacheItem.usages -= 1
    if (cacheItem.usages <= 0) {
      const timeout = setTimeout(() => cacheMap.delete(key), ttl)
      timeoutsRemoveMap.set(key, timeout)
    }
  }

  const _onItemUpdate = (item, fromSSE = true) => {
    if (fromSSE) item = convert(item)
    const cacheItem = cacheMap.get(itemToKey(item))
    if (cacheItem) cacheItem.item?.value?.merge(item)
  }

  const _onItemDelete = item => cacheMap.delete(itemToKey(convert(item)))

  if (!eventNameUpdated) eventNameUpdated = `${name}.update`
  if (!eventNameDeleted) eventNameDeleted = `${name}.delete`
  const events = Object.values(ServerEvent)
  if (!events.includes(eventNameUpdated) || !eventNameDeleted.includes(eventNameDeleted)) {
    throw new Error(
      'UPDATE/DELETE event is not found. Probably, you forget to add it to events map or made a mistake in entity name'
    )
  }
  // Connect update and delete to server SSE
  on(eventNameUpdated, _onItemUpdate)
  on(eventNameDeleted, _onItemDelete)

  /**
   * Uses specific service. This composable can automatically track used cache items in component
   * and unused them after unmount
   *
   * @param {boolean} [autoUnuse=true] The automatic unused after unmounted. Default is `true`
   *
   * @returns {{
   *   getById: Function
   *   unuse: Function
   *   useCacheItem: Function
   *   unuseCacheItem: Function
   *   mapperId: Function
   * }}
   *   Functions object for use service
   */
  const useService = (autoUnuse = true) => {
    const usedKeys = []

    /**
     * Get cached value by key.
     *
     * @param {String} key
     * @param {{ resetCache: boolean }} options Does it update value before return.
     *
     * @returns {Promise<any>} Value by `id`.
     */
    const getByKey = async (key, options = { resetCache: false }) => {
      return await navigator.locks.request(`service-${name}-${key}`, async () => {
        let cacheItem = cacheMap.get(key)
        options?.resetCache && cacheItem && _onItemUpdate((await apiGetByKey(key)).data, false)
        if (!cacheItem) {
          _setCacheItem(key, (await apiGetByKey(key)).data)
          usedKeys.push(key)
          cacheItem = cacheMap.get(key)
          if (!cacheItem) throw new Error(`Cannot load item for key ${key}`)
        }
        useCacheItem(key)
        return cacheItem.item
      })
    }

    /**
     * Sync id reference with api object.
     *
     * @param {Ref<any>} keyRef The identifier reference.
     * @param {Function} onError Callback.
     *
     * @returns {{
     *   item: ComputedRef<any>
     *   itemRef: Ref<any>
     *   loading: Ref<boolean>
     * }} Unpacked
     *   item, itemRef and loading status.
     */
    const mapperId = (keyRef, onError = undefined) => {
      if (!isRef(keyRef)) throw new Error('keyRef is not a ref')
      const itemRef = ref()
      const item = computed(() => itemRef.value?.value)
      const loading = ref(true)
      watch(
        keyRef,
        (newKey, oldKey) => {
          loading.value = true
          usedKeys.remove(oldKey)
          unuseCacheItem(oldKey)
          getByKey(newKey)
            .then(item => (itemRef.value = item))
            .catch(e => {
              itemRef.value = null
              onError?.(e)
            })
            .finally(() => (loading.value = false))
        },
        { immediate: true }
      )

      return {
        item,
        itemRef,
        loading,
      }
    }

    /** Invalidate cache. */
    const unuse = () => usedKeys.forEach(unuseCacheItem)
    autoUnuse && onUnmounted(unuse)

    /**
     * Manually update cached value from server.
     *
     * @param {String} key Identifier of cached object. In most cases `id`. In rare cases union of
     *   multiple ids.
     *
     * @returns {Promise<any>} Promise with actual object.
     */
    const refreshByKey = async key =>
      await getByKey(isRef(key) ? key.value : key, { resetCache: true })

    return {
      getByKey,
      unuse,
      useCacheItem,
      unuseCacheItem,
      mapperId,
      refreshByKey,
    }
  }

  return { useService }
}
